module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-modal-routing/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1000},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Arvo:400,700","Source Sans Pro:400"]}},
    },{
      plugin: require('../node_modules/gatsby-styled-components-dark-mode/gatsby-browser.js'),
      options: {"plugins":[],"light":{"bgColor1":"#c0bfbf","bgColor2":"#dfdede","contrast":"#4a4949","accent":"#79998b","font":"#222222","darker":"#ffffff"},"dark":{"bgColor1":"#212121","bgColor2":"#333333","contrast":"#adadad","accent":"#a9beb5","font":"#eeeeee","darker":"#141414"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Alex Kiefer - Softwareentwickler","short_name":"Alex Kiefer","start_url":"/","background_color":"#333333","theme_color":"#333333","display":"browser","icon":"src/img/icon.png"},
    }]
