// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-project-index-js": () => import("./../src/components/project/index.js" /* webpackChunkName: "component---src-components-project-index-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-alex-js": () => import("./../src/pages/alex.js" /* webpackChunkName: "component---src-pages-alex-js" */),
  "component---src-pages-corona-js": () => import("./../src/pages/corona.js" /* webpackChunkName: "component---src-pages-corona-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-projekt-js": () => import("./../src/pages/projekt.js" /* webpackChunkName: "component---src-pages-projekt-js" */),
  "component---src-pages-projekte-js": () => import("./../src/pages/projekte.js" /* webpackChunkName: "component---src-pages-projekte-js" */)
}

